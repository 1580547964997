/* requires:
polyfill.js
*/

// custom script for bootstrap video modal, only needed for page with video modals
// create video modal node after this script
const videoModal = document.createElement('div');
videoModal.setAttribute('id', 'video-modal');
videoModal.setAttribute('class', 'modal fade video-modal');
const videoModalHtml = `<div class="modal-dialog modal-lg modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-body p-1 py-0">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              <div class="ratio ratio-16x9">
                                <iframe id="video-iframe" src="" loading="lazy" width="853" height="480" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                              </div>
                            </div>
                          </div>
                        </div>`;
videoModal.innerHTML = videoModalHtml;
document.body.appendChild(videoModal);

let videoSrc;
const videoModalEl = document.getElementById('video-modal');

const videoModalToggleCollection = document.querySelectorAll('[data-bs-toggle="modal"]');
for (let videoModalToggle of videoModalToggleCollection) {
  videoModalToggle.addEventListener('click', (event) => {
    const modalTrigger = event.target.closest('[data-bs-toggle="modal"]');
    const targetSrc = modalTrigger.dataset?.src;
    videoSrc = targetSrc;
    if (videoSrc) videoModalEl.querySelector('#video-iframe').src = videoSrc;
  });
}
// removing src to prevent video from continuing to play in the background
videoModalEl.addEventListener('hide.bs.modal', (event) => {
  videoModalEl.querySelector('#video-iframe').src = '';
});
